<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <div class="row">

        <transition name="fade">
          <div class="accordion accordion-flush">

            <form @keydown="form.onKeydown($event)"
                  @submit.prevent="saveCarSearchChecklist"
                  v-if="onProgress === false">

              <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_general-heading">
                  <button class="accordion-button"
                          :class="validateBlock_general ? 'bg-green-active' : ''"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_general"
                          aria-expanded="true"
                          aria-controls="carSearchChecklist_general">
                    Общая информация
                  </button>
                </h2>
                <div id="carSearchChecklist_general"
                     class="accordion-collapse collapse show"
                     aria-labelledby="carSearchChecklist_general-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">

                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('general.owner_name') }">
                          <input class="form-control"
                                 name="name"
                                 placeholder="ФИО владельца"
                                 title="ФИО владельца"
                                 type="text"
                                 v-model="form.general.owner_name"
                                 required>
                          <HasError :form="form" field="general.owner_name" />
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('general.owner_phone') }">
                          <vue-tel-input v-bind="phone_config"
                                         v-model="form.general.owner_phone"
                                         required>
                          </vue-tel-input>
                          <HasError :form="form" field="general.owner_phone" />
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('general.inspection_date') }">
                          <v-date-picker
                            :masks="date_options.masks"
                            :max-date="date_options.maxDate"
                            :min-date="date_options.minDate"
                            :mode="'date'"
                            :model-config="date_options.formatDate"
                            :update-on-input="false"
                            color="blue"
                            locale="ru"
                            v-model="form.general.inspection_date"
                            trim-weeks
                            required>
                            <template v-slot="{inputValue, inputEvents}">
                              <input :value="inputValue"
                                     class="form-control"
                                     placeholder="Дата проверки"
                                     v-on="inputEvents" />
                            </template>
                          </v-date-picker>
                          <HasError :form="form" field="general.inspection_date" />
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('general.inspection_time') }">
                          <input type="time" class="form-control" v-model="form.general.inspection_time" required>
                          <HasError :form="form" field="general.inspection_time" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_car_info-heading">
                  <button class="accordion-button collapsed"
                          :class="validateBlock_car_info ? 'bg-green-active' : ''"
                          id="carSearchChecklist_car_info-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_car_info"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_car_info"
                          :disabled="!validateBlock_general">
                    Информация об авто
                  </button>
                </h2>
                <div id="carSearchChecklist_car_info"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_car_info-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="form-group">
                      <div class="row">

                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info.mark_id') }">
                          <multiselect :allow-empty="false"
                                       :options="marks"
                                       :searchable="true"
                                       @input="() => {
                                         form.car_info.mark_id = mark ? mark.id : null;
                                       }"
                                       @select="getCarModels"
                                       deselect-label="Убрать"
                                       label="name"
                                       placeholder="Марка"
                                       required
                                       select-label="Выбрать"
                                       selected-label="Выбрано"
                                       title="Марка"
                                       track-by="id"
                                       v-model="mark">
                            <template slot="noResult">Не найдено</template>
                            <template slot="noOptions">Данные отсутствуют</template>
                          </multiselect>
                          <HasError :form="form" field="car_info.mark_id" />
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info.model_id') }">
                          <multiselect :allow-empty="false"
                                       :disabled="!models || models.length === 0"
                                       :options="models"
                                       :searchable="true"
                                       @input="() => {
                                         form.car_info.model_id = model ? model.id : null;
                                       }"
                                       deselect-label="Убрать"
                                       label="name"
                                       placeholder="Модель"
                                       required
                                       select-label="Выбрать"
                                       selected-label="Выбрано"
                                       title="Модель"
                                       track-by="id"
                                       v-model="model">
                            <template slot="placeholder">
                              <i class="fas font-scale fa-circle-notch fa-spin text-blue"
                                 v-if="onProgressCarModels === true" />
                              Модель
                            </template>
                            <template slot="noResult">Не найдено</template>
                            <template slot="noOptions">Данные отсутствуют</template>
                          </multiselect>
                          <HasError :form="form" field="car_info.model_id" />
                        </div>

                        <div class="col-lg-2 col-md-3 col-sm-4 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info.reg_number') }">
                          <input class="form-control"
                                 id="reg_number"
                                 name="reg_number"
                                 maxlength="50"
                                 placeholder="Гос. номер"
                                 title="Гос. номер"
                                 type="text"
                                 v-model="form.car_info.reg_number"
                                 required>
                          <HasError :form="form" field="car_info.reg_number" />
                        </div>

                        <div class="col-lg-2 col-md-3 col-sm-4 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info.year') }">
                          <input :max="2023"
                                 :min="1900"
                                 class="form-control"
                                 id="year"
                                 name="year"
                                 placeholder="Год выпуска"
                                 required
                                 title="Год выпуска"
                                 type="number"
                                 v-model="form.car_info.year">
                          <HasError :form="form" field="car_info.year" />
                        </div>

                        <div class="col-lg-2 col-md-3 col-sm-4 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info.race') }">
                          <input :max="9999999"
                                 :min="1"
                                 class="form-control"
                                 id="race"
                                 name="race"
                                 placeholder="Пробег"
                                 required
                                 title="Пробег"
                                 type="number"
                                 v-model="form.car_info.race">
                          <HasError :form="form" field="car_info.race" />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_car_info_additional-heading">
                  <button class="accordion-button collapsed"
                          :class="validateBlock_car_info_additional ? 'bg-green-active' : ''"
                          id="carSearchChecklist_car_info_additional-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_car_info_additional"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_car_info_additional"
                          :disabled="!validateBlock_general || !validateBlock_car_info">
                    Детальная информация об авто
                  </button>
                </h2>
                <div id="carSearchChecklist_car_info_additional"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_car_info_additional-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">

                    <div class="form-group">
                      <div class="row">

                        <div class="col-lg-3 col-md-4 col-sm-4 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info_additional.vin_code') }">
                          <input :max="17"
                                 class="form-control"
                                 id="vin_code"
                                 name="vin_code"
                                 placeholder="VIN-код"
                                 title="VIN-код"
                                 type="text"
                                 v-model="form.car_info_additional.vin_code"
                                 required>
                          <HasError :form="form" field="car_info_additional.vin_code" />
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-4 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info_additional.equipment_title') }">
                          <input class="form-control"
                                 id="equipment_title"
                                 name="equipment_title"
                                 maxlength="255"
                                 placeholder="Название комплектации"
                                 title="Название комплектации"
                                 type="text"
                                 v-model="form.car_info_additional.equipment_title"
                                 required>
                          <HasError :form="form" field="car_info_additional.equipment_title" />
                        </div>

                        <div class="col-lg-2 col-md-3 col-sm-4 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('car_info_additional.origin_type') }">
                          <select class="form-control" v-model="form.car_info_additional.origin_type">
                            <option :value="null" class="text-muted">
                              Тип
                            </option>
                            <option :value="origin_type"
                                    v-for="origin_type in ['OFFICIAL', 'USA', 'EURO']"
                                    :selected="form.car_info_additional.origin_type === origin_type">
                              {{ origin_type }}
                            </option>
                          </select>
                          <HasError :form="form" field="car_info_additional.origin_type" />
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_body_check-heading">
                  <button class="accordion-button collapsed"
                          id="carSearchChecklist_body_check-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_body_check"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_body_check"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional
                        ">
                    Проверка кузова
                  </button>
                </h2>
                <div id="carSearchChecklist_body_check"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_body_check-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="table-responsive">
                      <table class="table table-borderless table-sm">
                        <thead>
                        <tr>
                          <th scope="col" class="border-top-0">Деталь</th>
                          <th scope="col" class="border-top-0">Повреждено</th>
                          <th scope="col" class="border-top-0">Покраска</th>
                          <th scope="col" class="border-top-0">Шпаклевка</th>
                          <th scope="col" class="border-top-0">Повреждения</th>
                          <th scope="col" class="border-top-0">Коррозия</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(bodySide, bodySideIndex) in form.body_check">
                          <tr v-for="(bodyItem, bodyItemIndex) in bodySide" v-if="!['photos', 'comment'].includes(bodySideIndex)">
                            <th scope="row" class="font-scale-1">
                              {{ bodyItem["title"] }}
                            </th>
                            <td>
                              <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch"
                                       v-model="form.body_check[bodySideIndex][bodyItemIndex]['is_damaged']">
                              </div>
                            </td>
                            <td>
                              <input maxlength="500"
                                     class="form-control"
                                     placeholder="Покраска (комментарий)"
                                     title="Покраска (комментарий)"
                                     type="text"
                                     v-model="form.body_check[bodySideIndex][bodyItemIndex]['paint_info']">
                            </td>
                            <td>
                              <input maxlength="500"
                                     class="form-control"
                                     placeholder="Шпаклевка (комментарий)"
                                     title="Шпаклевка (комментарий)"
                                     type="text"
                                     v-model="form.body_check[bodySideIndex][bodyItemIndex]['putty_info']">
                            </td>
                            <td>
                              <input maxlength="500"
                                     class="form-control"
                                     placeholder="Повреждения (комментарий)"
                                     title="Повреждения (комментарий)"
                                     type="text"
                                     v-model="form.body_check[bodySideIndex][bodyItemIndex]['damage_info']">
                            </td>
                            <td>
                              <input maxlength="500"
                                     class="form-control"
                                     placeholder="Коррозия (комментарий)"
                                     title="Коррозия (комментарий)"
                                     type="text"
                                     v-model="form.body_check[bodySideIndex][bodyItemIndex]['corrosion_info']">
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>

                    <div class="form-group mt-3">
                      <div class="row">
                        <div class="col-lg-5 col-md-6 col-sm-12 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('body_check.comment') }">
                            <textarea class="form-control"
                                      maxlength="3000"
                                      rows="5"
                                      placeholder="Комментарий к проверке кузова"
                                      v-model="form.body_check.comment"></textarea>
                          <HasError :form="form" field="body_check.comment" />
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="form-group col-12">
                        <transition name="fade">
                          <vue-dropzone v-if="onProgressLoadFile === false"
                                        id="dropzone-photos_body_check"
                                        data-form-block="body_check"
                                        :duplicateCheck="true"
                                        :options="dropzoneOptions"
                                        v-on:vdropzone-thumbnail="savePhoto"
                                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
                          </vue-dropzone>
                          <loading-processing v-else :size="60"></loading-processing>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_details_check-heading">
                  <button class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_details_check"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_details_check"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                          !validateBlock_body_check
                        ">
                    Детали
                  </button>
                </h2>
                <div id="carSearchChecklist_details_check"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_details_check-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <template v-for="(detailSide, detailSideIndex) in form.details_check">

                      <div class="table-responsive">
                        <table class="table table-borderless table-sm">
                          <thead>
                          <tr>
                            <th scope="col" class="border-top-0">Деталь</th>
                            <th scope="col" class="border-top-0">Повреждено</th>
                            <th scope="col" class="border-top-0">Комментарий</th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr v-for="(detailItem, detailItemIndex) in detailSide" v-if="detailItem['title']">
                            <th scope="row" class="font-scale-1">
                              {{ detailItem["title"] }}
                            </th>
                            <td>
                              <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch"
                                       v-model="form.details_check[detailSideIndex][detailItemIndex]['is_damaged']">
                              </div>
                            </td>
                            <td>
                              <input maxlength="500"
                                     class="form-control"
                                     placeholder="Комментарий"
                                     title="Комментарий"
                                     type="text"
                                     v-model="form.details_check[detailSideIndex][detailItemIndex]['comment']">
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="panel panel-default">
                        <div class="form-group col-12">
                          <transition name="fade">
                            <vue-dropzone v-if="onProgressLoadFile === false"
                                          :id="'dropzone-photos_details_check_' + detailSideIndex"
                                          data-form-block="details_check"
                                          :data-form-part="detailSideIndex"
                                          :duplicateCheck="true"
                                          :options="dropzoneOptions"
                                          v-on:vdropzone-thumbnail="savePhoto"
                                          v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
                            </vue-dropzone>
                            <loading-processing v-else :size="60"></loading-processing>
                          </transition>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_interior_condition-heading">
                  <button class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_interior_condition"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_interior_condition"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                          !validateBlock_body_check || !validateBlock_details_check
                        ">
                    Состояние салона
                  </button>
                </h2>
                <div id="carSearchChecklist_interior_condition"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_interior_condition-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="table-responsive">
                      <table class="table table-borderless table-sm">
                        <thead>
                        <tr>
                          <th scope="col" class="border-top-0">Элемент</th>
                          <th scope="col" class="border-top-0">Повреждено</th>
                          <th scope="col" class="border-top-0">Комментарий</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(interiorConditionItem, interiorConditionItemIndex) in form.interior_condition"
                            v-if="interiorConditionItemIndex !== 'comment'">
                          <th scope="row" class="font-scale-1">
                            {{ interiorConditionItem["title"] }}
                          </th>
                          <td>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch"
                                     v-model="form.interior_condition[interiorConditionItemIndex]['is_damaged']">
                            </div>
                          </td>
                          <td>
                            <input maxlength="500"
                                   class="form-control"
                                   placeholder="Комментарий"
                                   title="Комментарий"
                                   type="text"
                                   v-model="form.interior_condition[interiorConditionItemIndex]['comment']">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="form-group mt-3">
                      <div class="row">
                        <div class="col-lg-5 col-md-6 col-sm-12 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('interior_condition.comment') }">
                            <textarea class="form-control"
                                      maxlength="3000"
                                      rows="5"
                                      placeholder="Комментарий к состоянию салона"
                                      v-model="form.interior_condition.comment"></textarea>
                          <HasError :form="form" field="interior_condition.comment" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <div class="form-group col-12">
                        <transition name="fade">
                          <vue-dropzone v-if="onProgressLoadFile === false"
                                        :id="'dropzone-photos_interior_condition'"
                                        data-form-block="interior_condition"
                                        :duplicateCheck="true"
                                        :options="dropzoneOptions"
                                        v-on:vdropzone-thumbnail="savePhoto"
                                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
                          </vue-dropzone>
                          <loading-processing v-else :size="60"></loading-processing>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_technical_condition-heading">
                  <button class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_technical_condition"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_technical_condition"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                          !validateBlock_body_check || !validateBlock_details_check || !validateBlock_interior_condition
                        ">
                    Техническое состояние
                  </button>
                </h2>
                <div id="carSearchChecklist_technical_condition"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_technical_condition-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="table-responsive">
                      <table class="table table-borderless table-sm">
                        <thead>
                        <tr>
                          <th scope="col" class="border-top-0">Элемент</th>
                          <th scope="col" class="border-top-0">Повреждено</th>
                          <th scope="col" class="border-top-0">Комментарий</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(technicalConditionItem, technicalConditionItemIndex) in form.technical_condition"
                            v-if="technicalConditionItemIndex !== 'comment'">
                          <th scope="row" class="font-scale-1">
                            {{ technicalConditionItem["title"] }}
                          </th>
                          <td>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch"
                                     v-model="form.technical_condition[technicalConditionItemIndex]['is_damaged']">
                            </div>
                          </td>
                          <td>
                            <input maxlength="500"
                                   class="form-control"
                                   placeholder="Комментарий"
                                   title="Комментарий"
                                   type="text"
                                   v-model="form.technical_condition[technicalConditionItemIndex]['comment']">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="form-group mt-3">
                      <div class="row">
                        <div class="col-lg-5 col-md-6 col-sm-12 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('technical_condition.comment') }">
                            <textarea class="form-control"
                                      maxlength="3000"
                                      rows="5"
                                      placeholder="Комментарий к техническому состоянию"
                                      v-model="form.technical_condition.comment"></textarea>
                          <HasError :form="form" field="technical_condition.comment" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <div class="form-group col-12">
                        <transition name="fade">
                          <vue-dropzone v-if="onProgressLoadFile === false"
                                        :id="'dropzone-photos_technical_condition'"
                                        data-form-block="technical_condition"
                                        :duplicateCheck="true"
                                        :options="dropzoneOptions"
                                        v-on:vdropzone-thumbnail="savePhoto"
                                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
                          </vue-dropzone>
                          <loading-processing v-else :size="60"></loading-processing>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_computer_diagnostic-heading">
                  <button class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_computer_diagnostic"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_computer_diagnostic"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                          !validateBlock_body_check || !validateBlock_details_check ||
                          !validateBlock_interior_condition || !validateBlock_technical_condition
                        ">
                    Компьютерная диагностика
                  </button>
                </h2>
                <div id="carSearchChecklist_computer_diagnostic"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_computer_diagnostic-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="table-responsive">
                      <table class="table table-borderless table-sm">
                        <thead>
                        <tr>
                          <th scope="col" class="border-top-0">Код ошибки</th>
                          <th class="border-top-0">Расшифровка</th>
                          <th class="border-top-0">Активна</th>
                          <th class="border-top-0">В памяти</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(computerDiagnosticItem, computerDiagnosticItemIndex) in form.computer_diagnostic.items">
                          <th scope="row">
                            <input class="form-control"
                                   placeholder="Код ошибки"
                                   title="Код ошибки"
                                   type="number"
                                   v-model="form.computer_diagnostic.items[computerDiagnosticItemIndex]['code']">
                          </th>
                          <td>
                            <input class="form-control"
                                   maxlength="1000"
                                   placeholder="Комментарий"
                                   title="Комментарий"
                                   type="text"
                                   v-model="form.computer_diagnostic.items[computerDiagnosticItemIndex]['errors']">
                          </td>
                          <td>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch"
                                     v-model="form.computer_diagnostic.items[computerDiagnosticItemIndex]['is_fixed']">
                            </div>
                          </td>
                          <td>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch"
                                     v-model="form.computer_diagnostic.items[computerDiagnosticItemIndex]['in_memory']">
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" class="font-scale-1">
                            Пробег корректировался
                          </th>
                          <td>
                            <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" role="switch"
                                     v-model="form.computer_diagnostic.race_is_changed">
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th scope="row" class="font-scale-1">
                            Состояние АКБ
                          </th>
                          <td>
                            <div class="form-check form-switch">
                              <input class="form-control"
                                     placeholder="% емкости батареи"
                                     title="% емкости батареи"
                                     type="number"
                                     v-model="form.computer_diagnostic.battery_capacity">
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="form-group mt-3">
                      <div class="row">
                        <div class="col-lg-5 col-md-6 col-sm-12 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic.comment') }">
                            <textarea class="form-control"
                                      maxlength="3000"
                                      rows="5"
                                      placeholder="Комментарий к компьютерной диагностике"
                                      v-model="form.computer_diagnostic.comment"></textarea>
                          <HasError :form="form" field="computer_diagnostic.comment" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <div class="form-group col-12">
                        <transition name="fade">
                          <vue-dropzone v-if="onProgressLoadFile === false"
                                        :id="'dropzone-photos_computer_diagnostic'"
                                        data-form-block="computer_diagnostic"
                                        :duplicateCheck="true"
                                        :options="dropzoneOptions"
                                        v-on:vdropzone-thumbnail="savePhoto"
                                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
                          </vue-dropzone>
                          <loading-processing v-else :size="60"></loading-processing>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_photos-heading">
                  <button class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_photos"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_photos"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                          !validateBlock_body_check || !validateBlock_details_check ||
                          !validateBlock_interior_condition || !validateBlock_technical_condition ||
                          !validateBlock_computer_diagnostic
                        ">
                    Фотографии
                  </button>
                </h2>
                <div id="carSearchChecklist_photos"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_photos-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="panel panel-default">
                      <div class="form-group col-12">
                        <transition name="fade">
                          <vue-dropzone v-if="onProgressLoadFile === false"
                                        id="dropzone-photos"
                                        data-form-block="photos"
                                        :data-form-part="null"
                                        :duplicateCheck="true"
                                        :options="dropzoneOptions"
                                        v-on:vdropzone-thumbnail="savePhoto"
                                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
                          </vue-dropzone>
                          <loading-processing v-else :size="60"></loading-processing>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="carSearchChecklist_save-heading">
                  <button class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#carSearchChecklist_save"
                          aria-expanded="false"
                          aria-controls="carSearchChecklist_save"
                          :disabled="
                          !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                          !validateBlock_body_check || !validateBlock_details_check ||
                          !validateBlock_interior_condition || !validateBlock_technical_condition ||
                          !validateBlock_computer_diagnostic || !validateBlock_photos
                        ">
                    Сохранить чек-лист
                  </button>
                </h2>
                <div id="carSearchChecklist_save"
                     class="accordion-collapse collapse"
                     aria-labelledby="carSearchChecklist_save-heading"
                     data-bs-parent="#carSearchChecklist">
                  <div class="accordion-body mt-2">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('overall_condition_score') }">
                          <select class="form-control" v-model="form.overall_condition_score">
                            <option :value="null" class="text-muted">
                              Общая оценка состояния
                            </option>
                            <option :value="condition_score"
                                    v-for="condition_score in [5, 4, 3, 2, 1]"
                                    :selected="form.condition_score === condition_score">
                              {{ condition_score }} из 5
                            </option>
                          </select>
                          <HasError :form="form" field="overall_condition_score" />
                        </div>

                        <div class="col-lg-6 col-md-12 mb-3"
                             v-bind:class="{ 'has-error': form.errors.has('comment') }">
                            <textarea class="form-control"
                                      maxlength="3000"
                                      rows="6"
                                      placeholder="Комментарий"
                                      v-model="form.comment"></textarea>
                          <HasError :form="form" field="comment" />
                        </div>


                        <div class="col-12">
                          <Button :disabled="
                            !validateBlock_general || !validateBlock_car_info || !validateBlock_car_info_additional ||
                            !validateBlock_body_check || !validateBlock_details_check ||
                            !validateBlock_interior_condition || !validateBlock_technical_condition ||
                            !validateBlock_computer_diagnostic || !validateBlock_photos || !validateBlock_save ||
                            form.busy
                          "
                                  :form="form"
                                  :loading="form.busy"
                                  class="btn btn-lg btn-success pull-right"
                                  style="min-width: 100px;">
                            <i class="fa fa-save"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <loading-processing v-else></loading-processing>

          </div>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Form from "vform";
import form_params from "./form-params.json";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
import phone_config from "./../../../../assets/data/vue-tel-input-config.json";
import moment from "moment";
import VCalendar from "v-calendar";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

Form.axios = API.apiClient;

Vue.use(VCalendar, {});

moment.locale("ru");

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  name: "task-cars-search-checklist-create",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTelInput,
    vueDropzone: vue2Dropzone,
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    page_title: "Создать чек-лист автоподбора",

    form: new Form(form_params),

    marks: [],
    mark: null,
    models: [],
    model: null,

    phone_config: phone_config,
    dropzoneOptions: {
      "url": "/",
      "clickable": true,
      "autoQueue": false,
      "autoProcessQueue": false,
      "thumbnailWidth": 350,
      "thumbnailMethod": "contain",
      "disablePreviews": false,
      "createImageThumbnails": true,
      "maxFiles": 20,
      "maxFilesize": 25600000,
      "resizeWidth": 800,
      "resizeQuality": 0.8,
      "addRemoveLinks": true,
      "dictDefaultMessage": "<i class='fas fa-file-import font-scale-15'></i><p class='text-black font-small-xs mt-3'>Выберите или перетащите файлы для загрузки</p>",
      "img_extensions": [
        ".jpg", ".jpeg", ".png", ".gif", ".webp", ".heic",
      ],
    },

    onProgress: true,
    onProgressCarModels: false,
    onProgressLoadFile: false,

    date_options: {
      minDate: moment().subtract(3, "M").format(DATE_FORMAT),
      maxDate: moment().format(DATE_FORMAT),
      formatDate: {
        type: "string",
        mask: DATE_FORMAT,
      },
      masks: {
        input: DATE_FORMAT,
      },
    },
  }),

  async created() {
    try {
      this.form.general.inspection_date = moment().format(DATE_FORMAT);
      this.form.general.inspection_time = moment().format("HH:MM");

      const marks_response = await API.apiClient.get("/cars/marks", {
        params: {
          is_paginated: 0,
          sorting: "asc",
          sort_column: "name",
        },
      });

      this.marks = marks_response.data;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateBlock_general: function() {
      return this.form.general.owner_name && this.form.general.owner_phone.length >= 14 &&
        this.form.general.inspection_date && this.form.general.inspection_time;
    },
    validateBlock_car_info: function() {
      return this.form.car_info.mark_id && this.form.car_info.model_id && this.form.car_info.reg_number &&
        this.form.car_info.year && this.form.car_info.race;
    },
    validateBlock_car_info_additional: function() {
      return this.form.car_info_additional.vin_code && this.form.car_info_additional.equipment_title &&
        this.form.car_info_additional.origin_type;
    },
    validateBlock_body_check: function() {
      return this.validateBlock_general && this.validateBlock_car_info && this.validateBlock_car_info_additional &&
        this.form.body_check && this.form.body_check.front && this.form.body_check.side_left &&
        this.form.body_check.back && this.form.body_check.side_right;
    },
    validateBlock_details_check: function() {
      return this.validateBlock_general && this.validateBlock_car_info && this.validateBlock_car_info_additional &&
        this.validateBlock_body_check && this.form.details_check && this.form.details_check.front &&
        this.form.details_check.side_left && this.form.details_check.back && this.form.details_check.side_right;
    },
    validateBlock_interior_condition: function() {
      return this.validateBlock_general && this.validateBlock_car_info && this.validateBlock_car_info_additional &&
        this.validateBlock_body_check && this.validateBlock_details_check && this.form.interior_condition;
    },
    validateBlock_technical_condition: function() {
      return this.validateBlock_general && this.validateBlock_car_info && this.validateBlock_car_info_additional &&
        this.validateBlock_body_check && this.validateBlock_details_check && this.validateBlock_interior_condition &&
        this.form.technical_condition;
    },
    validateBlock_computer_diagnostic: function() {
      return this.validateBlock_general && this.validateBlock_car_info && this.validateBlock_car_info_additional &&
        this.validateBlock_body_check && this.validateBlock_details_check && this.validateBlock_interior_condition &&
        this.validateBlock_technical_condition && this.form.computer_diagnostic && this.form.computer_diagnostic.items;
    },
    validateBlock_photos: function() {
      return true;
    },
    validateBlock_save: function() {
      return this.validateBlock_general && this.validateBlock_car_info && this.validateBlock_car_info_additional &&
        this.validateBlock_body_check && this.validateBlock_details_check && this.validateBlock_interior_condition &&
        this.validateBlock_technical_condition && this.validateBlock_computer_diagnostic &&
        this.validateBlock_photos && this.form.overall_condition_score;
    },
  },

  watch: {
    validateBlock_general: function(checked) {
      if (checked) {
        this.openNextBlock("carSearchChecklist_car_info");
      }
    },
    validateBlock_car_info: function(checked) {
      if (checked) {
        this.openNextBlock("carSearchChecklist_car_info_additional");
      }
    },
    validateBlock_car_info_additional: function(checked) {
      if (checked) {
        this.openNextBlock("carSearchChecklist_body_check");
      }
    },
  },

  methods: {
    openNextBlock(blockID) {
      let clickEvent = new Event("click");
      document.getElementById(blockID + "-btn").dispatchEvent(clickEvent);
    },

    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.form.model_id = null;
        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
          "/cars/mark/" + mark.id + "/models",
          {
            params: {
              is_paginated: 0,
              sorting: "asc",
              sort_column: "name",
            },
          },
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    async savePhoto(file) {
      try {
        this.onProgressLoadFile = true;

        let currentFormBlockName = file.previewTemplate.parentElement.getAttribute("data-form-block");
        let currentFormPartName = file.previewTemplate.parentElement.getAttribute("data-form-part");

        if (currentFormBlockName === "body_check") {
          this.form[currentFormBlockName]["photos"].push(file.dataURL);
        } else if (currentFormBlockName === "details_check") {
          this.form[currentFormBlockName][currentFormPartName]["photos"].push(file.dataURL);
        } else if (currentFormBlockName === "interior_condition") {
          this.form[currentFormBlockName]["photos"].push(file.dataURL);
        } else if (currentFormBlockName === "technical_condition") {
          this.form[currentFormBlockName]["photos"].push(file.dataURL);
        } else if (currentFormBlockName === "computer_diagnostic") {
          this.form[currentFormBlockName]["photos"].push(file.dataURL);
        } else {
          this.form.photos.push(file.dataURL);
        }

      } catch (error) {
        errorHandler(error);
      }
      this.onProgressLoadFile = false;
    },

    async saveCarSearchChecklist() {
      await this.form.post("/task/" + this.taskId + "/cars_search/add").then(() => {
        showSuccess();
        setTimeout(() => window.location.reload(), 500);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style scoped>
.show {
  display: block;
}

.accordion-item {
  border: 1px solid #dee2e6 !important;
}

.accordion-button {
  font-size: 20px;
  font-weight: 600;
  padding: 35px 0;
}

.accordion-button:hover {
  opacity: 0.85;
}

.accordion-button:disabled {
  color: #6c757d;
  background: #dbdbdd;
}

.accordion-button:disabled.accordion-button:after {
  opacity: 0.5;
}

.accordion-button:after {
  width: 25px;
  height: 25px;
  background-size: 25px;
}

.multiselect__tags {
  min-height: 45px;
}

.table-borderless {
  border: none !important;
}
</style>
